@import url('https://fonts.cdnfonts.com/css/open-sans');
@import url('https://fonts.cdnfonts.com/css/gotham');

/*font-family: 'Open Sans', sans-serif;
font-family: 'Open Sans Light', sans-serif;
font-family: 'Open Sans Condensed', sans-serif;*/

/*font-family: 'Gotham', sans-serif;
font-family: 'Gotham Black', sans-serif;
font-family: 'Gotham Light', sans-serif;
font-family: 'Gotham Thin', sans-serif;
font-family: 'Gotham XLight', sans-serif;
font-family: 'Gotham Book', sans-serif;
font-family: 'Gotham Ultra', sans-serif;*/

.clr { clear:both; } 

body {
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  background-color: #ffffff;
}

.body-bg {
  background-size: cover;
  background-repeat: no-repeat;
}

.layout_padding {
  padding: 75px 0;
}

.layout_padding2 {
  padding: 50px 0;
}

.layout_padding2-top {
  padding-top: 50px;
}

.layout_padding2-bottom {
  padding-bottom: 50px;
}

.layout_padding-top {
  padding-top: 75px;
}

.layout_padding-bottom {
  padding-bottom: 75px;
}

/*header section*/

#shopify-section-announcement-bar {
    background: #f7e46e;
    padding: 8px 0 10px;
    text-align: center;
}
#shopify-section-announcement-bar .announcement__text, 
#shopify-section-announcement-bar .announcement__text a {
  font-size: 14px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1px;
  color: #0a0a0a;
}
#shopify-section-announcement-bar .announcement__text {
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
}
.hero_area {
  /*background-color: #fdf2d1;*/
  background-color: #f0f0f0;
}

.hero_area.sub_pages {
  height: auto;
}

.hero_area.sub_pages {
  height: auto;
}

.header_section {
    background: #ffffff;
}

.header_section .container-fluid {
  /*padding-right: 25px;
  padding-left: 25px;*/ 
  max-width: 1170px;
}

.header_section .nav_container {
  margin: 0 auto;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link img {
  width: 22px;
  margin-right: 15px;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link {
  /*text-transform: uppercase;*/
letter-spacing: 1px;
  color: #0A0A0A;
  text-align: center;
}

li.nav-item {
    letter-spacing: 1px;
    /*text-transform: uppercase;*/
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}
.custom_menu-btn button {
  margin-top: 0px;
  width: 21px;
  height: 21px;
  outline: none;
  border: none;
  background-image: url(../images/menu.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0; 
  /*background-color: rgba(255, 255, 255, 0.9);*/
  background-color: #fff;    
  overflow-x: hidden;
  transition: 0.5s;
    
  max-width: 400px;
    
}

.overlay a.closebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  
  font-size: 40px;
  /*background-color: #0a0a0a;
  color: #fff;*/
  color: #0a0a0a;
  position: absolute;
  top: 20px;
  right: 20px;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: left;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding:0 20px;
}

.overlay-content a {
  font-size: 18px !important;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2em;
    margin-bottom: 6px;
    font-family: 'open sans';
    color: #0a0a0a;
  margin-bottom: 20px;
  z-index: 99;
    letter-spacing: 2px;
}

.overlay-content a:hover {
  background-color: #fff;
  border: 1px solid transparent;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.custom_nav-container .nav_search-btn {
  background-image: url(../images/search-icon.png);
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;   
  padding: 0;
  border: none;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  /*width: 45px;*/
  max-height: 55px;
  margin-right: 5px;
}

.navbar-brand span {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  font-weight: 700;
  color: #fefefe;
}

.custom_nav-container {
  z-index: 99999;
  padding: 15px 0;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(../images/menu.png);
  background-size: 55px;
}

/*end header section*/

/* slider section */

.slider_item-container .row .col-md-6 {
  padding: 0;
}

.slider_item-container .row {
  align-items: center;
}

.slider_item-detail {
  color: #fefefe;
}

.slider_item-detail .slider_heading {
  font-weight: 500;
  font-size: 75px;
  font-family: 'Gotham Thin', sans-serif;
  text-transform: lowercase;
  color: #0A0A0A;
  letter-spacing: 7px;
}

.slider_item-detail p {
  margin: 45px 0;
  text-align: left;
    color: #0a0a0a;
    letter-spacing: 1px;
    max-width: 900px;    
    font-family: 'Gotham Light', sans-serif;
}

.slider_call-btn {
  display: inline-block;
  padding: 8px 20px;
  background-color: #edb040;
  color: #fefdfd;
  border-radius: 20px;
}

.slider_section #carouselExampleControls,
.carousel-inner,
.carousel-item {
  height: 100%;
}

.slider_section .carousel-control-prev,
.slider_section .carousel-control-next {
  position: absolute;
  left: 2.5%;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center;
  background-color: #fff;
  transform: translateY(-50%);
}

.slider_section .carousel-control-prev:hover,
.slider_section .carousel-control-next:hover {
  background-color: #292727;
  background-size: 12px;
}

.slider_section .carousel-control-prev {
  top: 50%;
  background-image: url(../images/left-black-arrow.png);
}

.slider_section .carousel-control-next {
  top: 59%;
  background-image: url(../images/right-black-arrow.png);
}

.slider_section .carousel-control-prev:hover {
  background-image: url(../images/prev.png);
}

.slider_section .carousel-control-next:hover {
  background-image: url(../images/next.png);
}

.slider_section::before {
  content: "01/02";
  position: absolute;
  top: 7%;
  left: 3%;
  opacity: 0.6;
  color: #fefefe;
}

.slider_section::after {
  position: absolute;
  content: "02/03";
  bottom: 7%;
  left: 3%;
  opacity: 0.6;
  color: #fefefe;
}

.hero_img-box {
    max-width: 550px;
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
}

.hero_img-box img {
  width: 100%;
}

.slider_btn {
  display: inline-block;
    color: #0a0a0a;
    border: 2px solid #0a0a0a;
    padding: 12px 30px; 
    margin-bottom: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
}

.slider_btn:hover {
  background-color: #fefeff;
  color: #1e1f1f;
  border-color: transparent;
}

/* end slider section */

/* detail section */
.detail_section {
  /*padding-top: 45px;*/
    padding-bottom: 100px;
}

.detail_container {
  display: flex;
  padding-top: 14%;
}

.detail-box {
  min-width: 150px;
  min-height: 150px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
}

.detail-box::before {
  content: "";
  position: absolute;
  width: 220px;
  height: 220px;
  top: auto;
  left: auto;
  background-color: #f0f0f0;
  z-index: -1;
  border-radius: 100%;
  transition: transform 1s;
  transform: rotate();
}

.detail-box.d-box-1::before,
.detail-box.d-box-3::before {
  clip: rect(110px, 220px, 220px, 0);
}

.detail-box:hover {
  cursor: pointer;
}

.detail-box:hover::before {
  transform: rotate(360deg);
}

.detail-box.d-box-2::before,
.detail-box.d-box-4::before {
  clip: rect(0, 220px, 110px, 0);
}

.detail-box h5 {
  font-size: 16px;
  color: #f0f0f0;
  margin-top: 7px;
}

.detail-box.d-box-1,
.detail-box.d-box-4 {
  background-color: #0a0a0a;
}

.detail-box.d-box-2 {
  background-color: #f7e46e; 
}
.detail-box.d-box-2 h5 { 
  color: #0a0a0a;
}
.detail-box.d-box-3 {
  background-color: #0a0a0a;
}
.detail-box.d-box-4 {
  background-color: #f7e46e; 
}
.detail-box.d-box-4 h5 { 
  color: #0a0a0a;
}

.detail-box .detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-box .detail-content img {
  height: 50px;
  padding: 5px;
}

/* end detail section */

/* product section */
.products_section {
  /*margin-top: -90px;*/
    /*margin-top: 100px;*/
    background: url(https://cdn.shopify.com/s/files/1/0063/8792/5105/files/newletter-bg_17c79770-6b4c-4d10-9c9c-30a4dac5a78d.png?v=1667822566) repeat top;
    background-size: 22px !important;
    padding-top: 80px;
}

.sub_page .products_section {
  margin-top: 75px;
}

.products_section .heading_container {
  display: flex;
  flex-direction: column;
  align-items: left;
    max-width: 1170px;
    margin: 0 auto;
}

.products_section .heading_container h2 {
  font-weight: bold;
  text-align: left;
    padding-top: 20px;
    font-size: 60px;
    line-height: 1.2;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}

.product_container {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  
}

.product_box {
  /*width: 250px;
  height: 350px;*/
  width: 29%;
  display: flex;
  flex-direction: column; 
  margin: 0 4% 5% 0;
    background: #fff;
    padding-top: 2%;
    float: left;
    padding-bottom: 2%;
}

.product_img-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product_img-box img {
  width: 100%;
}

.product_img-box span {
  display: inline-block;
  background-color: #ec4045;
  color: #fefeff;
  padding: 2px 17px;
  margin-top: -30px;
  margin-bottom: 15px;
}

.product_detail-box {
  text-align: center;
  color: #717171;
  margin-top: 10px;
}
.product_detail-box p {
    color: #0a0a0a;
    font-size: 15px;
    padding: 0 5%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.2em;
    margin-bottom: 5px;
}
.product_detail-box span {
    color: #0a0a0a;
    font-size: 16px;
    padding: 0 5%;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.2em;
    margin-bottom: 15px; 
}

/* end product section */

/* find section */
.find_section {
  text-align: center;
}

.find_section img {
  width: 100%;
}

.find_section p {
  color: #e12121;
  margin: 0;
}

.find_container,
.shop_container {
  background-color: #f3f3f3;
  margin: 20px 0;
}

.find_section .row {
  align-items: center;
}

.find_container-img {
  padding: 35px 0 35px 40px;
}

.shoe_img-box {
  padding: 35px 35px 35px 0;
}

.shoe_img-box img {
  width: 85%;
}

.find_container h3 {
  font-size: 32px;
}

.shop_container h3 {
  font-size: 26px;
}

.shop_container a {
  display: inline-block;
  padding: 5px 12px;
  background-color: #ff47d9;
  color: #fefeff;
  margin-top: 10px;
}

/* end find section */

/* client section */
.client_section {
  /*background-color: #edece8;*/
  background: #ebebeb;
  border-bottom: 1px solid #0a0a0a;
}

.client_section h2 {
  color: #fefefe;
  text-align: center;
    
    border-bottom: 2px solid;
    width: fit-content;
    color: #0a0a0a;
    padding: 2px 70px 2px 0px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-size: 1.1em;
}

.client_box {
  /*background-color: #f6f1ed;*/
  padding: 25px 25px 25px 0;
  color: #313232;
}

.client_id-box {
  display: flex;
  align-items: center;
}

.client_id-box .client_img-box {
  width: 75px;
  height: 75px;
  background-color: #313232;
  display: flex;
  justify-content: center;
}

.client_id-box .client_img-box img {
  height: 100%;
}

.client_id-box h4 {
  margin: 0;
  margin-left: 25px;
}

.client_detail {
  margin: 35px 0 50px 0;
}

.client_section .carousel-control-prev,
.client_section .carousel-control-next {
  position: absolute;
  top: initial;
  bottom: -50px;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: center;
  background-color: #fff;
  transform: translateY(-50%);
}

.client_section .carousel-control-prev:hover,
.client_section .carousel-control-next:hover {
  background-color: #292727;
  background-size: 12px;
}

.client_section .carousel-control-prev {
  left: 0;
  background-image: url(../images/left-black-arrow.png);
}

.client_section .carousel-control-next {
  right: 0;
  background-image: url(../images/right-black-arrow.png);
}

.client_section .carousel-control-prev:hover {
  background-image: url(../images/prev.png);
}

.client_section .carousel-control-next:hover {
  background-image: url(../images/next.png);
}

.item_container {
  background-color: #f3f3f3;
  text-align: center;
  padding: 25px;
  margin-top: 100px;
}

.item_container img {
  width: 100%;
}

.item_container .row {
  align-items: center;
}

.item_img-box {
  padding: 35px 35px 35px 0;
}

.item_img-box img {
  width: 85%;
}

.item_container h3 {
  font-size: 32px;
}

.item_container p {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.item_container a {
  display: inline-block;
  padding: 5px 12px;
  background-color: #ff47d9;
  color: #fefeff;
}

/* end client section */

/* sign section */
.sign_section {
  /* background-image: url(../images/sign.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  color: #fefefe;
}

.sign_section h3 {
  text-transform: uppercase;
}

.sign_section .row {
  align-items: center;
}

.sign_section form {
  display: flex;
  justify-content: flex-end;
}

.sign_section form input {
  width: 75%;
  border: none;
  outline: none;
  padding: 10px 15px;
}

.sign_section form button {
  padding: 10px 35px;
  outline: none;
  border: none;
  background-color: #272727;
  color: #fefeff;
}

/* end sign section */

/* info section */
.info_section {
  /*background-color: #272727;*/
}

.links_container {
  color: #fefeff;
}

.links_container h3 {
  font-size: 13px;
  /*text-transform: uppercase;*/
    
  font-weight: bold;
    letter-spacing: 3px; 
    width: fit-content;
    padding-right: 30px; 
    padding-bottom: 4px;
    background: #0a0a0a;
    color: #fff !important;
    padding-top: 4px;
    padding-left: 9px;
    text-transform: uppercase;
}

.links_container ul {
  padding: 15px 0 0 0;
}

.links_container li { 
    font-weight: 100;
    line-height: 1.5;
    color: #0A0A0A;
    text-align: left;
    letter-spacing: 1px;
   font-size: 0.9em;
   text-transform: lowercase;
    margin: 1.25em 0;
    list-style-type: none;
}

.links_container li a { 
  color: #0A0A0A;
}

.footer .social-icons {
    height: auto;
    margin-top: 0;
    margin-left: 0;
    display: block;
  }  
.footer .social-icons a {
    width: 100%;
    float: left;
    display: block;
    height: 40px;
  }
.footer .social-icons a svg { 
    display: inline-block; 
    margin-right: 15px;
    margin-top: 1px;
    float: left;
    width: 23px;   
    margin-right: 10px;
  }



.mu-partner-logos li {
  height: 40px;
  margin: 1em 0;
}
.mu-partner-logos img {
  height: 40px;
}

.follow_container {
  margin-top: 50px;
}

.follow_container .row {
  align-items: center;
}

.app_container {
  display: flex;
  align-items: center;
}

.app_container h3 {
  font-size: 24px;
  margin-right: 20px;
  color: #fefeff;
}

.app_container>div {
  display: flex;
  align-items: center;
}

.app_container img {
  width: 175px;
  margin-right: 10px;
}

.info_social {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.info_social img {
  width: 40px;
  margin-left: 8px;
}

/* end info section */

/* footer section*/

.footer_section {
  /*background-color: #f6f1ed;*/
  max-width: 1000px;
  padding: 0px 3%;
  padding-top: 20px;
}

.footer_section p {
  color: #030000;
  margin: 0;
  text-align: center;
}

.footer_section a {
  color: #030000;
}

.footer-text-sdr p,
.footer_section p {
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  color: gray;
  letter-spacing: 0.6px;
  font-family: Gotham Light,sans-serif;
  font-family: "Open Sans", sans-serif;
  color: var(--color-text);
  padding-bottom: 20px;    
}

/* end footer section*/


/* PDP section starts */
.pdp-section {
    width: 100%; 
    background: url(https://cdn.shopify.com/s/files/1/0063/8792/5105/files/newletter-bg_17c79770-6b4c-4d10-9c9c-30a4dac5a78d.png?v=1667822566) repeat top;
    background-size: 22px !important;
    padding-top: 80px;
    padding-bottom: 80px;
}
.pdp-section-inner {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.pdp-section-left {
    width: 50%;
    float: left;
}

.pdp-section-right-description {
    width: 100%;
    float: left;
    background: #f0f0f0;
    font-size: 1em; 
    margin-top: 30px; 
    margin-bottom: 30px;  
    padding: 5% 8%;
}

.pdp-section-right {
    width: 45%;
    float: right;
    text-align: left;
}

.pdp-section-right-title {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 1.8em;
    font-family: 'Gotham Light', sans-serif;
    letter-spacing: 1px; 
    line-height: 1.2em;
    margin-bottom: 20px;
}
.pdp-section-right-brand {
    width: auto;
    float: left;
    text-align: left;
    font-size: 0.8em;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    /*background: #0a0a0a;*/
    border: 2px solid #0a0a0a;
    display: block;
    padding: 2px 9px;
    color: #0a0a0a;
}

.pdp-section-right-price {
    width: 100%;
    float: left;
    font-size: 2.8em;
    font-weight: 100;
    padding-top: 30px;
    
}

button.pdp-section-right-cart-button {
    float: left;
    border: 0;
    font-size: 23px;
    padding: 10px 27px;
    background: #f7e46e;
    font-weight: 100;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 10px;
}
button.pdp-section-right-cart-button:hover {
    background: #0a0a0a;
    color: #fff;
}

ul.pdp-section-right-bullet {
    width: 100%;
    float: left;
    font-size: 1em; 
    margin-top: 30px; 
    margin-bottom: 30px; 
    background: #fff;
    padding: 5% 8%;
}

.pdp-section-right-bullet li {
    width: 100%;
    float: left;
    list-style-type: disc;
    list-style-position: outside;
    padding-bottom: 15px;
}

.pdp-section-right.mobile,
.pdp-section-right-description.mobile {
    display: none;
}


/*** PDP Gallery starts ***/
/** https://codepen.io/ludmila-tretyakova/pen/bGNppGG **/

.pdp-section-gallery {
    width: 100%; 
    height: 100%;
    display: grid;
    /*grid-template-rows: 5fr 1fr;*/
    /*background: #021919;*/
    position: relative;
}


.pdp-section-gallery ul {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    /*display: flex;*/
    
    display: inline-block;
    width: 15%;
}

.pdp-section-gallery li {
    
}

.pdp-section-gallery .tab {
    /*width: calc(10vh + 8px);
    height: calc(10vh + 8px);*/
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    /*align-items: center;
    justify-content: center;*/
    /*clip-path: polygon(0% 50%, 15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%);
    shape-outside: polygon(0% 50%, 15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%);*/
    z-index: 0;
    /*transition: width 0.5s;*/
}

.pdp-section-gallery .tab img {
    /*width: 10vh;
    height: 10vh;*/
    width: 100%;
    height: 10%;
    z-index: 10;
    cursor: pointer;
    /*clip-path: polygon(0% 50%, 15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%);
    shape-outside: polygon(0% 50%, 15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%);*/
    /*transition: width 0.5s;*/
}

.pdp-section-gallery [type=radio] {
    display: none;   
}

.pdp-section-gallery .preview-list {
    background: linear-gradient(
        #021919,
        #021919 var(--line-offset),
        #efefef var(--line-offset)
    );
}

.pdp-section-gallery .tab {
    background: linear-gradient(
        #efefef,
        #efefef var(--line-offset),
        #021919 var(--line-offset)
    );
}

.pdp-section-gallery [type=radio]:checked ~ label ~ .content{
    text-align: center;
    z-index: 8;
}

.pdp-section-gallery [type=radio]:checked ~ label .tab{
    width: 0;
}

.pdp-section-gallery label { 
  margin-bottom: .5rem;
}

.pdp-section-gallery .content {
    position: absolute;
    background: #021919;
    /*top: 1vh;*/
    top: 0;
    /*left: 0;*/
    right: 0;
    /*width: 100vw;*/
    width: 83%;
    height: 83%;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: #fff; 
}

.pdp-section-gallery .content img {
    /*height: auto;
    width: 100vw;  */
    width: 100%;
    height: 100%;
}
/*** PDP Gallery ends ***/

/* PDP section ends */


/* SupplyCentral Store starts */
.store-top-strip {
    border-bottom: 1px solid #0a0a0a;
    background: #59c1a8 !important;
}
.store-header {
    background: #f7e46e;
}
.hp-banner-desktop {
    
}
.hp-banner-mobile {
    display: none;
}
.store-section-2 {
   width: 100%;
   background: #fff;
   padding-top: 120px;
   /*padding-bottom: 120px; */
}
.store-section-2-inner {
   max-width: 1170px;
   margin: 0 auto;
   
}
.store-section-2-box {
    width: 38%;
    margin-right: 2%;
    background: #59c1a8;
    float: left;
}
.store-section-2-text {
    /*width: 58%; 
    float: right;*/
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}
.store-section-2-text h2 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 30px;
}
.store-section-2-text p {
    font-size: 18px; 
    line-height: 28px;
    max-width: 800px;
    margin: 0 auto;
}
.store-section-3 {
   width: 100%;
   background: #fff;
   padding-top: 120px;
   padding-bottom: 120px;  
   
}
.store-section-3-inner {
   max-width: 1170px;
   margin: 0 auto;
   
}
.store-section-3-text {
    width: 23%;
    margin-right: 0%;
    float: left;
}
.store-section-3-text h3 {
    width: 100%; 
    float: left;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 15px;
}
.store-section-3-text h5 {
    width: 100%; 
    float: left;
    font-size: 15px; 
    line-height: 21px;
}
.store-section-3-boxes {
    width: 23%;
    margin-left: 2%;
    background: #59c1a8;
    float: right;
    padding: 3%;
    text-align: center;
    /*border: 1px solid #0a0a0a;*/
}
.store-section-3-boxes h3 {
    width: 100%; 
    float: left;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.store-section-3-boxes img {
    max-height: 60px;
    margin-bottom: 20px;    
}

.store-pdt-4 {
    width: 21%;
}
.client_section h2 {
    border: 0;
    font-size: 17px;
    font-style: italic;
    letter-spacing: 2px;
    background: #0a0a0a;
    color: #fff;
    padding: 2px 10px 3px;
}
.store-button-section {
    background: url(https://cdn.shopify.com/s/files/1/0063/8792/5105/files/newletter-bg_17c79770-6b4c-4d10-9c9c-30a4dac5a78d.png?v=1667822566) repeat top;
    background-size: 22px !important; 
    padding-top: 75px;
    padding-bottom: 75px;
}
.store-button-section-inner {
    max-width: 1170px;   
    margin: 0 auto;
}
a.store-button-india {
    width: 45%;
    float: left;
    display: block;
    background: #f7e46e;
    padding: 2% 5%;
    margin-right: 5%;
    color: #0a0a0a;
    display: block;
    cursor: pointer;
    box-shadow: 10px 10px;
    border: 1px solid #0a0a0a;
}
.store-button-india img {
    /*max-width: 80px;*/
    min-width: 60px;
    float: right;
    width: 25%;
}
.store-button-india span {
    width: 75%;
    float: left;
    font-size: 25px;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
    color: #0a0a0a;
    font-weight: bold;
    /*line-height: 84px;*/
    padding-top: 36px;
}
a.store-button-usa {
    width: 45%;
    float: right;
    display: block;
    background: #59c1a8;
    padding: 2% 5%; 
    color: #0a0a0a;
    display: block;
    cursor: pointer;
    box-shadow: 10px 10px;
    border: 1px solid #0a0a0a;
}
.store-button-usa img { 
    min-width: 60px;
    float: right;
    width: 25%;
}
.store-button-usa span {
    width: 75%;
    float: left;
    font-size: 25px;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
    color: #0a0a0a;
    font-weight: bold;
    /*line-height: 84px;*/
    padding-top: 36px;
}

@media (max-width: 700px) {
    .hp-banner-desktop {
        display: none;
    }
    .hp-banner-mobile {
        display: block;
    }
    .store-section-2-inner,
    .store-section-3-inner {
        padding-left: 15px;
        padding-right: 15px;
    }
    .store-section-3-text,
    .store-section-3-boxes { 
        width: 47%;
        float: left;
        display: block;
        margin-bottom: 20px;
        height: 260px;
        margin-left: 0;
        margin-right: 3%;
    }
    .store-section-3-boxes { 
        padding-top: 10%;
    }
    a.store-button-india,
    a.store-button-usa {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 50px;
        float: left;
        margin-left: 4%;
    }
}

/* SupplyCentral Store ends */



/* Bootstrap overrides starts */
.mt-2,
.my-2 {
  margin-top: 0.1rem !important;
}
/* Bootstrap overrides ends */